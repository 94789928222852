import React, { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import TagInputs from "../layouts/TagInputs";
import { usePatient } from "../../contexts";
import SpeechToTextButton from "../UI/SpeechToTextButton";
import AdministerMed from "./AdministerMed";

function NurseNotesAdd({ closeModal, treatment, doctors, nurses, fetch, currentPage }) {
    const [payload, setPayload] = useState({});
    const { patientName } = usePatient();
    const [loading, setLoading] = useState(false);
    const [showAdministerMed, setShowAdministerMed] = useState(false);
    const [selectedMedication, setSelectedMedication] = useState(null);
    const [source, setSource] = useState('')
    const medications = treatment?.medications || [];
    const otherMedications = treatment?.otherMedications || [];

    const handleAdministerMed = (medication, source) => {
        setSelectedMedication(medication);
        setSource(source)
        setShowAdministerMed(true);
    };

    const handleChange = (event, name) => {
        if (name === "nurse") {
            setPayload(prevPayload => ({ ...prevPayload, [name]: Number(event?.value) }));
        } else {
            setPayload(prevPayload => ({ ...prevPayload, [name]: event?.target?.value }));
        }
    };

    const handleTranscript = (transcript) => {
        setPayload(prevPayload => ({ ...prevPayload, note: prevPayload.note ? prevPayload.note + ' ' + transcript : transcript }));
    };

    const getDoctorName = (doctorId) => {
        const doctor = doctors?.find((doctor) => doctor?.value === Number(doctorId));
        return doctor ? doctor?.name : "Doctor Not Found";
    };

    const doctorname = getDoctorName(treatment?.doctorId);

    return (
        <div className='overlay'>
            <RiCloseFill className='close-btn pointer' onClick={() => closeModal(false)} />
            <div className="modal-content">
                <div className="flex ">
                    <div className="flex flex-v-center m-t-20 m-l-10 col-6">
                        <p className="bold-text m-r-10">Patient's Medications</p> | <p className={'m-l-10'}>{patientName}</p>
                    </div>
                </div>

                {/* <TagInputs
                    type={'R-select'}
                    options={nurses?.map((nurse) => ({ label: nurse?.name, value: nurse?.value }))}
                    label="Administering Nurse"
                    name="nurse"
                    onChange={(e) => handleChange(e, 'nurse')}
                /> */}
                <TagInputs
                    options={doctors}
                    label="Assigned Doctor"
                    value={doctorname}
                    name="additionalNoteOnTreatment"
                    readOnly={true}
                />

                {medications.length > 0 && (
                    <>
                        <h4>Medications</h4>
                        <table className="bordered-table-2 m-t-20">
                            <thead>
                                <tr>
                                    <th>Medication</th>
                                    <th>Frequency</th>
                                    <th>Quantity</th>
                                    <th>Duration</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {medications.map((medication) => (
                                    <tr key={medication?.id}>
                                        <td>{medication?.name}</td>
                                        <td>{medication?.frequency} times daily</td>
                                        <td>{medication?.quantity}mg</td>
                                        <td>{medication?.duration} days</td>
                                        <td>
                                            <button
                                                className="save-drafts"
                                                onClick={() => handleAdministerMed(medication, 'Medications')}
                                            >
                                                Administer
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}

                {otherMedications.length > 0 && (
                    <>
                        <h4>Other Medications</h4>
                        <table className="bordered-table-2 m-t-20">
                            <thead>
                                <tr>
                                    <th>Medication</th>
                                    <th>Frequency</th>
                                    <th>Quantity</th>
                                    <th>Duration</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {otherMedications.map((medication) => (
                                    <tr key={medication?.id}>
                                        <td>{medication?.name}</td>
                                        <td>{medication?.frequency} times daily</td>
                                        <td>{medication?.quantity}mg</td>
                                        <td>{medication?.duration} days</td>
                                        <td>
                                            <button
                                                className="save-drafts"
                                                onClick={() => handleAdministerMed(medication, 'OtherMedications')}
                                            >
                                                Administer
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </>
                )}

                {/* <div className="">
                    <TagInputs
                        label="Administering Nurse's Comment"
                        name="note"
                        value={payload.note}
                        onChange={(e) => handleChange(e, 'note')}
                        type='textArea'
                    />
                    <SpeechToTextButton onTranscript={handleTranscript} />
                </div> */}


                {showAdministerMed && (
                    <AdministerMed
                        medication={selectedMedication}
                        treatment={treatment}
                        nurses={nurses}
                        source={source}
                        closeModal={() => setShowAdministerMed(false)}
                    />
                )}
            </div>
        </div>
    );
}

export default NurseNotesAdd;
