import React, { useState } from "react";
import { RiCloseFill } from "react-icons/ri";
import TagInputs from "../layouts/TagInputs";
import notification from "../../utility/notification";
import { usePatient } from "../../contexts";
import { post } from "../../utility/fetch";
import SpeechToTextButton from "../UI/SpeechToTextButton";

function AdministerMed({ medication, closeModal, nurses, doctors, treatment, source }) {
    const { patientId, patientName } = usePatient();
    const [loading, setLoading] = useState(false)
    const [payload, setPayload] = useState({
        administeredDate: "",
        administeredTime: "",
        volumeGiven: "",
        units: "",
        otherNotes: "",
    });

    const handleChange = (event, name) => {
        if (name === "doctorId" || name === "nurse") {
            setPayload(prevPayload => ({ ...prevPayload, [name]: Number(event?.value) }));
        } else {
            setPayload(prevPayload => ({ ...prevPayload, [name]: event?.target?.value }));
        }
    };


    console.log(source)
    const administerMed = async () => {
        setLoading(true);
        const formattedTime = payload.administeredTime
            ? { hours: parseInt(payload.administeredTime.split(":")[0], 10), minutes: parseInt(payload.administeredTime.split(":")[1], 10) }
            : null;

        const data = {
            administeredBy: nurses.find(nurse => nurse.value === payload.nurse)?.name,
            treatmentId: treatment?.id,
            patientId: Number(patientId),
            clinicId: Number(sessionStorage.getItem("clinicId")),
            otherNotes: payload?.otherNotes,
            units: payload?.units,
            administeredDate: payload?.administeredDate,
            administeredTime: formattedTime,
            volumeGiven: Number(payload?.volumeGiven),
            medicationName: medication?.name,
            medicationSource: source,
            medicationId: medication?.id || 0,
        };

        const requiredFields = {
            otherNotes: "Additional Notes",
            units: "Units",
            administeredDate: "Administered Date",
            administeredTime: "Administered Time",
            volumeGiven: "Volume Given",
        };

        const checkMissingFields = (payload) => {
            const missingFields = Object.keys(requiredFields).filter(field => !payload[field]);
            return missingFields;
        };

        const missingFields = checkMissingFields(payload);
        if (missingFields.length > 0) {
            const missingFieldLabels = missingFields.map(field => requiredFields[field]);
            notification({ message: `Missing required fields: ${missingFieldLabels.join(", ")}`, type: "error" });
            setLoading(false);
            return;
        }

        try {
            const res = await post(`/patients/prescription-log`, data);
            if (res) {
                notification({ message: 'Medication administered successfully', type: "success" });
                closeModal();
            } else {
                notification({ message: 'Failed to administer medication', type: "error" });
            }
        } catch (err) {
            notification({ message: 'Failed to administer medication', type: "error" });
        } finally {
            setLoading(false);
        }
    };

    const handleTranscript = (transcript) => {
        setPayload(prevPayload => ({ ...prevPayload, otherNotes: prevPayload.otherNotes ? prevPayload.otherNotes + ' ' + transcript : transcript }));
    };

    return (
        <div className="overlay">
            <RiCloseFill className="close-btn pointer" onClick={closeModal} />
            <div className="modal-content">
                <h4>Administer Medication</h4>
                <p><strong>Medication:</strong> {medication?.name}</p>

                <TagInputs type={'R-select'} options={nurses?.map((nurse) => {
                    return {
                        label: nurse?.name,
                        value: nurse?.value
                    }
                })} label="Administering Nurse" name="nurse" onChange={(e) => handleChange(e, 'nurse')} />
                <TagInputs
                    label="Administered Date"
                    name="administeredDate"
                    type="date"
                    onChange={(e) => handleChange(e, 'administeredDate')}
                />
                <TagInputs
                    label="Administered Time"
                    name="administeredTime"
                    type="time"
                    onChange={(e) => handleChange(e, 'administeredTime')}
                />
                <TagInputs
                    label="Volume Given"
                    name="volumeGiven"
                    variation='number'
                    onChange={(e) => handleChange(e, 'volumeGiven')}
                />
                <TagInputs
                    label="Units"
                    name="units"
                    onChange={(e) => handleChange(e, 'units')}
                />
                <TagInputs
                    label="Other Notes"
                    name="otherNotes"
                    type="textArea"
                    value={payload?.otherNotes}
                    onChange={(e) => handleChange(e, 'otherNotes')}
                />
                <SpeechToTextButton onTranscript={handleTranscript} />

                <button className="submit-btn m-t-20 w-100" onClick={administerMed} disabled={loading}>
                    Submit
                </button>
            </div>
        </div>
    );
}

export default AdministerMed;
